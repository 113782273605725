<template>
  <p class="clearfix mb-0 text-center">
    <span class="float-md-center d-block d-md-inline-block mt-25">
      <span class="d-sm-inline-block"><small>{{ $t('footer.simple_text_1', { copyright: '©' + new Date().getFullYear() }) }}</small></span>
      <span class="d-none d-sm-inline-block"><small>, {{ $t('footer.simple_text_2') }}</small></span>
    </span>
    <br/>
    <span class="float-md-right d-md-block"><small>{{ version }}</small></span>
  </p>
</template>

<script>
import defaultConfig from '@core-md/config'

export default {
  data() {
    return {
      version: defaultConfig.VERSION_ID,
    }
  },
}
</script>
