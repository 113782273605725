<script>
import { $themeConfig } from '@themeConfig'

export default {
  name: 'vue-title',
  props: ['title'],
  created() {
    document.title = `${this.appName} - ${this.title}`
  },
  watch: {
    title() {
      document.title = `${this.appName} - ${this.title}`
    },
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app

    return {
      appName,
    }
  },
  render() {
    return null
  },
}
</script>
