export default {

  setup() {
    if (document.getElementById('recaptchaScript') === null) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
      recaptchaScript.setAttribute('id', 'recaptchaScript')
      document.head.appendChild(recaptchaScript)
    }
  },
}
