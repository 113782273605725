<template>
  <div class="auth-wrapper auth-v2">
    <HTMLTitle :title="$t('forgot_password.page_title')"></HTMLTitle>
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <app-logo v-if="skin !== 'dark'" />
        <app-logo-dark v-if="skin === 'dark'" />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Redefinição de Senha" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('forgot_password.title') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2" v-html="$t('forgot_password.subtitle')">
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPasswordForm" #default="{invalid}">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent>
              <b-form-group label-for="forgot-password-email">
                <validation-provider #default="{ errors }" :name="$t('common.email')" rules="required|email">
                  <b-form-input id="forgot-password-email" v-model="form.email" :state="errors.length > 0 ? false:null"
                    name="forgot-password-email" :placeholder="$t('common.email')" maxlength="100"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible"
                :sitekey="recaptchaKey">
              </vue-recaptcha>

              <b-button type="button" variant="primary" :disabled="invalid || formSubmitted" block @click="preSubmit">
                <feather-icon v-if="!formSubmitted" size="16" icon="SendIcon" class="mr-50" />
                <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
                <span class="align-middle">{{ $t('forgot_password.submit_button') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('common.back') }}
            </b-link>
          </p>

          <simple-Navbar />

          <footer class="footer footer-light footer-static">
            <slot name="footer">
              <simple-footer />
            </slot>
          </footer>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppLogo from '@core-md/layouts/components/Logo.vue'
import AppLogoDark from '@core-md/layouts/components/LogoDark.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import VueRecaptcha from 'vue-recaptcha'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import Recaptcha from '@core-md/utils/recaptcha'
import Crypt from '@core-md/utils/crypt'
import useJwt from '@core-md/services/auth/useJwt'
import defaultConfig from '@core-md/config'
import handleAxiosException from '@core-md/mixins/errors'

import { $themeConfig } from '@themeConfig'
import store from '@/store/index'

import SimpleNavbar from '@/layouts/components/SimpleNavbar.vue'
import SimpleFooter from '@/layouts/components/SimpleFooter.vue'

import 'animate.css'

export default {
  components: {
    AppLogo,
    AppLogoDark,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    HTMLTitle,
    VueRecaptcha,
    SimpleNavbar,
    SimpleFooter,
  },
  mixins: [handleAxiosException],
  data() {
    return {
      form: {
        email: '',
        recaptchaToken: '',
      },
      recaptchaKey: defaultConfig.RECAPTCHA_KEY,
      formSubmitted: false,
      // validation
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const { spinnerType } = $themeConfig.layout

    return {
      appName,
      appLogoImage,
      spinnerType,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return require('@/assets/images/pages/forgot-password-v2-dark.svg')
      }
      return require('@/assets/images/pages/forgot-password-v2.svg')
    },
    skin() {
      return store.state.appConfig.layout.skin
    },
  },
  mounted() {
    Recaptcha.setup()

    this.showModal()
  },
  methods: {
    preSubmit() {
      this.$refs.recaptcha.execute()
    },
    submitForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const token = Crypt.signToken(this.form)
          const request = token
          useJwt.forgotPassword({
            request,
          })
            .then(response => {
              const result = Crypt.decrypt(response.data.result)

              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('forgot_password.messages.error'))
                this.$refs.forgotPasswordForm.setErrors(result.statusMessageDetail || result.statusMessage)
              }
              this.formSubmitted = false
            })
            .catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'forgot_password.messages.error')
            })
        }
      })
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset()
      this.form.recaptchaToken = recaptchaToken
      this.submitForm()
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    showModal() {
      this.$swal({
        title: this.$i18n.t('forgot_password.modal.title'),
        html: this.$i18n.t('forgot_password.modal.text'),
        icon: 'info',
        confirmButtonText: this.$i18n.t('common.gotit'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
