<template>
  <b-navbar class="header-navbar">
    <div class="navbar-container d-flex content align-items-center">
      <b-navbar-nav class="nav align-items-center ml-auto flex-row">
        <locale />
        <dark-Toggler />
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>

<script>
import {
  BNavbar, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from './SimpleLocale.vue'

export default {
  components: {
    // Navbar Components
    BNavbar,
    BNavbarNav,
    Locale,
    DarkToggler,
  },
}
</script>
